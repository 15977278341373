<template>
  <info-section
    v-if="userScores && Object.keys(userScores).length"
    :pending="competenceFetchStatus === 'pending'"
    icon="mdi-numeric-10-box-multiple"
    title="Баллы по компетенциям"
  >
    <div v-if="competenceFetchStatus === 'success'">
      <div
        v-for="(value, key) in userScores"
        :key="'group' + key"
        class="group-item"
      >
        <div>
          <strong>{{ value.title }}</strong>
        </div>
        <div v-for="group in value.competences" :key="group.title">
          <div v-for="(data, year) in group.results" :key="year" class="line">
            <option-row
              ><span slot="title">{{ group.title }}: </span>{{ data.score }} ({{
                year
              }}
              г.)</option-row
            >
            <div v-if="data.updatedAt" class="text-caption">
              <option-row title="Баллы обновлены:"
                ><span>{{ data.updatedAt }}</span></option-row
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="competenceFetchStatus === 'error'">
      <div v-for="group in userScores" :key="group.title">
        <div v-for="(score, year) in group.results" :key="year">
          <div>{{ group.title }}: {{ score }} ({{ year }} г.)</div>
        </div>
      </div>
    </div>

    <a :href="calconLink" target="_blank" rel="noopener noreferrer"
      >Баллы в конструкторе калькуляторов</a
    >
  </info-section>
</template>

<script>
import { numCases } from "@/utils";
import InfoSection from "@/components/InfoSection.vue";
import { TALENT_COMPETENCE_GROUP_CODE } from "@/constants";
import dayjs from "@/plugins/dayjs";
export default {
  name: "TalentScores",
  components: { InfoSection },
  props: {
    scores: {
      type: Array,
    },
  },
  data() {
    return {
      //pending, success, error
      competenceFetchStatus: "pending",
    };
  },
  computed: {
    calconLink() {
      return `${process.env.VUE_APP_CALCON_ORIGIN}/scores?search=${this.talentUser.id}`;
    },
    talentUser() {
      return this.$store.state.talent.users[this.$route.params.id];
    },
    talentGroupId() {
      return Object.values(this.competencesGroups).find(
        (group) => group.code === TALENT_COMPETENCE_GROUP_CODE
      )?.id;
    },
    competences() {
      return this.$store.state.talent.competences || {};
    },
    competencesGroups() {
      return this.$store.state.talent.competencesGroups || {};
    },
    /**
     * Баллы участника сгруппированные по группам компетенций
     */
    userScores() {
      const { scores, competences, competenceFetchStatus } = this;
      if (!scores?.length || competenceFetchStatus === "pending") return;

      const userScores = scores.reduce((acc, value) => {
        if (!acc[value.competence_id] && value.score) {
          acc[value.competence_id] = {
            title:
              competences[value.competence_id]?.name ||
              `Компетенция ${value.competence_id}`,
            results: {},
            competenceId: value.competence_id,
            groupId: competences[value.competence_id]?.group_id,
          };
        }

        if (value.score) {
          const date = value.updated_at || value.created_at || null;
          acc[value.competence_id].results[value.year] = {
            score: `${value.score} ${numCases(
              ["балл", "балла", "баллов"],
              value.score
            )}`,
            ...(date && { updatedAt: dayjs(date).format("LLL") }),
          };
        }
        return acc;
      }, {});
      /*если не смогли получить компетенции, то возвращаем как есть,
      т.к. не сможем привязать к группам*/
      if (competenceFetchStatus === "error") {
        return userScores;
      } else {
        return Object.values(userScores).reduce((res, scores) => {
          const groupId = scores.groupId;
          const isTalentGroup = scores.groupId === this.talentGroupId;
          const isCompetenceActive = competences[scores.competenceId].is_active;
          /*если по группе компетенций - "конкурс Талант" компетенция не отображается в ЛК
          то не выводим ее в списке*/
          if (isTalentGroup && !isCompetenceActive) {
            return res;
          }
          if (!res[groupId]) {
            res[groupId] = {
              title:
                this.competencesGroups[groupId]?.name ||
                `Группа компетенций ${groupId}`,
              competences: {
                [scores.competenceId]: scores,
              },
            };
          } else {
            res[groupId].competences[scores.competenceId] = scores;
          }
          return res;
        }, {});
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.getCompetences();
        this.$store.dispatch("talent/getCompetencesGroups");
      } catch (error) {
        console.log("error", error);
      }
    },
    async getCompetences() {
      try {
        await this.$store.dispatch("talent/getCompetences");
        this.competenceFetchStatus = "success";
      } catch (error) {
        this.competenceFetchStatus = "error";
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.line {
  & + & {
    margin-top: 10px;
  }
}
.group-item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
}
</style>

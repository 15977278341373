<template>
  <v-row :key="updateKey">
    <v-col lg="6" xs="12" sm="12">
      <common-section />
      <!-- Баллы по компетенциям -->
      <talent-scores
        v-if="isSuperuser && talentUser"
        :scores="scores"
        class="mb-4"
      />
      <!-- Соц. Аккаунты -->
      <social-accounts
        :socials="socials"
        :all-user-socials="allUserSocials"
        @setPage="setAccountsPage"
      />
      <!-- Место работы  -->
      <place-of-work
        :workplaces="workplaces"
        @setPage="getWorkPlaces"
        @onSubmit="getWorkPlaces"
        @handleRemoveWorkplace="handleRemoveWorkplace"
      />

      <interests-section :user-id="talentId"></interests-section>
    </v-col>
    <v-col lg="6" md="12" sm="12">
      <duplicate-section
        v-if="talentUser"
        :user="talentUser"
      ></duplicate-section>
      <!-- Теги  -->
      <tags-section
        :tags="tags"
        @handleRemoveTag="handleRemoveTag"
        @handleTagClick="handleTagClick"
      />
      <!-- Комментарии  -->
      <comments-section
        :comments="comments"
        @setPage="getComments"
        @removeComment="handleRemoveComment"
        @onSuccess="getComments(1)"
      />
      <!-- Воронки -->
      <funnels-section :records="records" />
    </v-col>
  </v-row>
</template>
<script>
import TalentScores from "@/components/contact/TalentScores.vue";
import { apiClient, talentClient } from "@/api";
import { initialListingModel } from "@/utils";
import {
  CommonSection,
  SocialAccounts,
  PlaceOfWork,
  InterestsSection,
  DuplicateSection,
  TagsSection,
  CommentsSection,
  FunnelsSection,
} from "@/components/contact/sections";

const initialData = () => ({
  scores: [],
  tags: [],
  records: [],
  workplaces: initialListingModel(1),
  comments: initialListingModel(5),
  updateKey: 0,
});
export default {
  name: "ContactCommonPage",
  components: {
    TalentScores,
    CommonSection,
    SocialAccounts,
    PlaceOfWork,
    InterestsSection,
    DuplicateSection,
    TagsSection,
    CommentsSection,
    FunnelsSection,
  },
  metaInfo() {
    return {
      title: `Карточка контакта`,
    };
  },
  data() {
    return {
      ...initialData(),
    };
  },
  computed: {
    isSuperuser() {
      return this.$store.getters["user/isSuperuser"];
    },
    talentUser() {
      return this.$store.state.talent.users[this.$route.params.id];
    },
    talentId() {
      return +this.$route.params.id;
    },
    socials() {
      return this.$store.state.contact.socials;
    },
    allUserSocials() {
      const { list, telegramAccounts, kaggleAccounts } = this.socials;
      return [...list, ...telegramAccounts, ...kaggleAccounts];
    },
  },

  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        Object.assign(this.$data, initialData());
        this.init();
      }
    },
  },
  activated() {
    this.init();
    this.updateKey += 1;
  },
  methods: {
    init() {
      this.getContact();
      this.getTalentScores();
      this.getComments(this.comments.page);
      this.getWorkPlaces(this.workplaces.page);
    },
    /**
     * Получение информации о баллах за конкурс таланта
     * доступно только суперюзерам
     */
    async getTalentScores() {
      if (!this.isSuperuser) return;
      try {
        const { data } = await talentClient({
          baseURL: "/calcon",
          url: "/scores",
          params: {
            user_id: this.talentId,
            hide_zeros: true,
          },
        });
        this.scores = data.results;
      } catch (error) {
        this.$toast(
          `Не удалось получить баллы пользователя за конкурс Талант. ${error.message}`,
          {
            type: "error",
          }
        );
      }
    },

    async getContact() {
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/contacts/${this.talentId}`,
        });
        this.records = data.Records;
        this.tags = data.tags;
      } catch {
        //do nothing
      }
    },
    setAccountsPage(page) {
      this.$store.commit("contact/SET_SOCIALS_STATE", {
        page,
      });
    },
    async getWorkPlaces(page = 1) {
      if (this.workplaces.pending) return;
      this.workplaces.error = "";
      this.workplaces.pending = true;
      this.workplaces.page = page;

      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/contacts/${this.talentId}/workplaces`,
          params: {
            limit: this.workplaces.limit,
            offset: (this.workplaces.page - 1) * this.workplaces.limit,
          },
        });
        this.workplaces = {
          ...this.workplaces,
          list: data.results,
          total: data.count,
          pagesCount: Math.ceil(data.count / this.workplaces.limit),
        };
      } catch (error) {
        this.workplaces.error = error.message;
      }
      this.workplaces.pending = false;
    },
    async handleRemoveWorkplace(workplace_id) {
      const confirm = await this.$root.$confirm(
        "Удалить место работы",
        `Вы действительно хотите удалить это место работы у контакта?`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );
      if (confirm) {
        try {
          await apiClient({
            method: "DELETE",
            url: `/contacts/${this.talentId}/workplaces/${workplace_id}`,
          });
          this.getWorkPlaces(1);
          this.$toast("Аватар удален", { type: "success" });
        } catch (error) {
          this._showError(`Не удалось удалить место работы. ${error.message}`);
        }
      }
    },
    async handleRemoveTag(tag) {
      //  Спрашиваем
      const confirm = await this.$root.$confirm(
        "Удалить тег у контакта",
        `Вы действительно хотите удалить тег &laquo;${tag.name}&raquo; у контакта?`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );

      if (!confirm) return;

      if (confirm) {
        try {
          // удаляем
          await apiClient({
            method: "DELETE",
            url: `/contacts/${this.talentId}/tags`,
            data: { tags: [tag.id] },
          });
          const idx = this.tags.findIndex((n) => n.id === tag.id);
          // обновляем локальный стейт, если все оки
          this.$delete(this.tags, idx);
        } catch (error) {
          this._showError("Не удалось удалить тег, возникла ошибка");
        }
      }
    },
    async handleTagClick(tag) {
      try {
        await apiClient({
          method: "POST",
          url: `/contacts/${this.talentId}/tags`,
          data: { tags: [tag.id] },
        });
        this.tags.push(tag);
      } catch (error) {
        this._showError("Не удалось добавить тег, возникла ошибка");
      }
    },
    async getComments(page = 1) {
      const { comments } = this;
      if (comments.pending) return;
      comments.error = "";
      comments.pending = true;
      comments.page = page;

      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/contacts/${this.talentId}/comments`,
          params: {
            limit: comments.limit,
            offset: (comments.page - 1) * comments.limit,
          },
        });
        comments.list = data.results;
        comments.total = data.count;
        comments.pagesCount = Math.ceil(data.count / comments.limit);
      } catch (error) {
        comments.error = error.message;
      }
      comments.pending = false;
    },
    async handleRemoveComment(id) {
      try {
        await apiClient({
          method: "DELETE",
          url: `/contacts/${this.talentId}/comments/${id}`,
        });
        this.getComments(this.comments.page);
      } catch (error) {
        this._showError(`Не удалось удалить комментарий. ${error.message}`);
      }
    },
  },
};
</script>
